<template>
    <div class=" mb-6">
        <div class="border-2 border-dashed border-gray-200 -mx-2 px-4 py-2 rounded-lg">
            <div class="" v-for="(entity, idx) in blockData.entities" :key="idx + (blockData._id || blockData.multi_id || blockData.block_id)">
                <template v-if="entity.form_entity_type === 'LAYOUT'">
                    <Layout
                        :layoutData="entity"
                        :path="[...path, {field: 'layout_id', value: entity.layout_id}]"
                        :validations="validations[idx].entities"
                    />
                </template>
                <template v-if="entity.form_entity_type === 'FIELD'">
                    <FormField
                        :fieldData="entity"
                        :key="entity.field_id"
                        :path="[...path, {field: 'field_id', value: entity.field_id}]"
                        :isMulti="checkIfMulti(entity, blockData.entities)"
                        :validations="validations[idx]"
                    />
                </template>
            </div>
            <div class="flex text-right" v-if="showRemoveBtn">
                <span class="ml-auto cursor-pointer capitalize text-brand hover:text-brand-hover" @click="removeBlock(blockData)"> - Remove {{ getBlockName }} </span>
            </div>
        </div>
        <div class="flex text-right">
            <span
                v-if="isMulti"
                class="ml-auto cursor-pointer capitalize text-brand hover:text-brand-hover"
                @click="copyEntity(path)"
            > + Add {{ getBlockName }} </span>
            <span
                v-if="blockData.is_copy"
                class="ml-auto cursor-pointer capitalize text-brand hover:text-brand-hover" 
                @click="removeEntity(path)"
            >Remove</span>
        </div>
    </div>
</template>

<script>
import Layout from "./layoutForm.vue";
import FormField from "./formField.vue";
import { EventBus } from "@/main.js";
import { checkIfMulti } from "../utils"

export default {
    props: {
        blockData: {
            type: Object,
            default: () => {},
        },
        index: {
            type: Number,
            default: 0,
        },
        path: Array,
        isMulti: {
            type: Boolean,
            default: false,
        },
        validations: {
            type: Object,
            default: () => null,
        },
    },
    components: {
        Layout,
        FormField,
    },
    data() {
        return {
            checkIfMulti,
        }
    },
    computed: {
        isBlockMulti() {
            return this.blockData.is_block_multi;
        },
        isBlockDuplicate() {
            return this.blockData.is_duplicate;
        },
        getBlockName() {
            return this.blockData?.block_name || "";
        },
        showMultiAddBtn() {
            return this.blockData.is_last || false;
        },
         
        showRemoveBtn() {
            return (!this.blockData.next &&  this.blockData.previous) || (this.blockData.next &&  !this.blockData.previous )
        },
       
    },
    methods: {
        copyEntity(path) {
            EventBus.$emit("copyEntity", path);
        },
        removeEntity(path) {
            EventBus.$emit("removeEntity", path);
        },
        // addAnotherBlock(block) {
        //     EventBus.$emit("addNewBlock", { block, block_id: block._id });
        // },
        // removeBlock(block) {
        //     EventBus.$emit("removeBlock", { block,_id: block._id });
        // }
    },
};
</script>

<style>
</style>