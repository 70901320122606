<template>
    <div
        class="flex flex-col mb-4"
        :class="['form-field', {
            'form-field--invalid': isError
        }]"
    >
        <!-- Dont show label for toggle  -->
        <div class="flex" v-if="getFieldType !== 'toggle'">
            <label for="#" class="form-label inline-block mb-2 text-gray-500" v-text="fieldData.label" />
            <span class="text-lg ml-2 inline text-red-500" v-if="fieldData.mandatory">*</span>
        </div>
        <component
            :is="fieldConfig[getFieldType].tag"
            v-bind="fieldConfig[getFieldType].props"
            :fieldData="fieldData"
            :key="fieldData.field_id"
            v-model="fieldValue"
        />
        <small class="text-gray-400 inline-block mt-2 text-sm" v-if="getHelpText" v-text="getHelpText" />
        <small
            v-if="fieldData.error_message"
            class="text-red-600 inline-block mt-2 text-sm"
        >
            {{ fieldData.error_message }}
        </small>
        <div class="flex text-right">
            <span
                v-if="isMulti"
                class="ml-auto cursor-pointer capitalize text-brand hover:text-brand-hover mr-2"
                @click="copyEntity(path)"
            >Add</span>
            <span
                v-if="fieldData.is_copy"
                class="ml-auto cursor-pointer capitalize text-brand hover:text-brand-hover" 
                @click="removeEntity(path)"
            >Remove</span>
        </div>
    </div>
</template>

<script>
import { EventBus } from "@/main.js";

const InputField = () => import("./form-components/input.vue");
const TextareaField = () => import("./form-components/textarea.vue");
const RadioField = () => import("./form-components/radio.vue");
const CheckboxField = () => import("./form-components/checkbox.vue");
const DropdownField = () => import("./form-components/dropdown.vue");
const ToggleField = () => import("./form-components/toggle.vue");
const DateField = () => import("./form-components/date.vue");
const FileField = () => import("./form-components/file_upload.vue");
const MultiselectField = () => import("./form-components/multiselect.vue"); // TODO

export default {
    // components: {
    //     InputField,
    //     TextareaField,
    //     RadioField,
    //     CheckboxField,
    //     DropdownField,
    //     ToggleField,
    //     DateField,
    //     FileField
    // },
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
        path: Array,
        isMulti: {
            type: Boolean,
            default: false,
        },
        validations: {
            type: Object,
            default: () => null,
        },
    },
    computed: {
        fieldConfig() {
            return {
                'Input': {
                    tag: InputField,
                },
                'Large text': {
                    tag: TextareaField,
                },
                'Number': {
                    tag: InputField,
                    props: {
                        type: 'number',
                    },
                },
                'Radio': {
                    tag: RadioField,
                },
                'Radio group': {
                    tag: RadioField,
                },
                'Single select': {
                    tag: DropdownField,
                },
                'Multi select': {
                    tag: MultiselectField,
                },
                'Date range': {
                    tag: DateField,
                },
                'File': {
                    tag: FileField,
                },
                'Checkbox': {
                    tag: CheckboxField,
                },
                'Checkbox group': {
                    tag: CheckboxField,
                },
                'Toggle': {
                    tag: ToggleField,
                },
                'Consent"': {
                    tag: CheckboxField,
                },
            }
        },
        fieldValue: {
            get() {
                return this.fieldData.value;
            },
            set(value) {
                EventBus.$emit("changeFieldValue", { path: this.path, value });
            }
        },
        getFieldType() {
            return this.fieldData?.type;
        },
        getHelpText() {
            return this.fieldData?.description_text ?? "";
        },
        getComponentField() {
            return this.getFieldType + '-field'
        },
        isError() {
            return this.validations?.value.$error || !!this.fieldData.error_message;
        },
    },
    methods: {
        copyEntity(path) {
            EventBus.$emit("copyEntity", path);
        },
        removeEntity(path) {
            EventBus.$emit("removeEntity", path);
        },
    },
};
</script>

<style lang="scss" scoped>
.form-field {
    &--invalid {
        ::v-deep {
            input {
                @apply border-red-600;
            }
        }
    }
}
</style>