// const sections = 

import FormBuilder from "./components/dynamicFormBuilder.vue";
import { EventBus } from "@/main.js";
import { get, set, cloneDeep } from "lodash";
// import { generateRandomString } from "@/utils/functions.js";
import { required } from "vuelidate/lib/validators";


const customValidators = {
    regex: (regexString) => (val) => (new RegExp(regexString)).test(val),
}

export default {
    name: 'neo-form-builder',
    components: {
        FormBuilder
    },
    props: {
        data: Object,
        requiered: true,
    },
    data() {
        return {
            sections_data: [
                {
                    "section_order": "1000",
                    "section_name": "Test Section 1",
                    "section_id": "test_1",
                    "has_error": false,
                    "entities": [
                        {
                            "order": "100",
                            "form_entity_type": "BLOCK",
                            "block_id": "first_test",
                            "block_name": "Test block",
                            "is_block_multi": true,
                            "block_repeat_max": "2",
                            "block_repeat_min": "",
                            "entities": [
                                {
                                    "form_entity_type": "LAYOUT",
                                    "layout_max_constraint": 12,
                                    "layout_id": "newlayoutsd",
                                    "entities": [
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "name",
                                            "label": "Name (letters only)",
                                            "type": "input",
                                            "placeholder": "Please enter name",
                                            "mandatory": true,
                                            "value": "TestName",
                                            "multi": false,
                                            "editable": true,
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "^[a-zA-Z]+$",
                                            "width_constraint_max": 6,
                                            "width_constraint_max_lg": 6,
                                            "width_constraint_max_sm": 12,
                                            "width_constraint_max_md": 6,
                                            "error_message": "",
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "email",
                                            "label": "email",
                                            "type": "input",
                                            "placeholder": "Please email",
                                            "mandatory": true,
                                            "value": "",
                                            "multi": true,
                                            "editable": true,
                                            "field_repeat_max": "3",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6,
                                            "width_constraint_max_lg": 6,
                                            "width_constraint_max_sm": 12,
                                            "width_constraint_max_md": 6,
                                            "error_message": "",
                                        },
                                    ]
                                },
                            ]
                        },
                    ]
                },
                {
                    "section_order": "1001",
                    "section_name": "Test Section 2",
                    "section_id": "test_2",
                    "has_error": false,
                    "entities": [
                        {
                            "order": "100",
                            "form_entity_type": "BLOCK",
                            "block_id": "3",
                            "block_name": "Test block",
                            "is_block_multi": true,
                            "block_repeat_max": "2",
                            "block_repeat_min": "",
                            "entities": [
                                {
                                    "form_entity_type": "LAYOUT",
                                    "layout_max_constraint": 12,
                                    "layout_id": "newlayoutsd",
                                    "entities": [
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "3",
                                            "label": "Field 3",
                                            "type": "input",
                                            "placeholder": "Please fill in",
                                            "mandatory": true,
                                            "value": "",
                                            "multi": false,
                                            "editable": true,
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6,
                                            "width_constraint_max_lg": 6,
                                            "width_constraint_max_sm": 12,
                                            "width_constraint_max_md": 6,
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "4",
                                            "label": "Field 4",
                                            "type": "input",
                                            "placeholder": "Please fill in",
                                            "mandatory": false,
                                            "value": "",
                                            "multi": true,
                                            "editable": true,
                                            "field_repeat_max": "3",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6,
                                            "width_constraint_max_lg": 6,
                                            "width_constraint_max_sm": 12,
                                            "width_constraint_max_md": 6,
                                            "error_message": "",
                                        },
                                    ]
                                },
                            ]
                        },
                    ]
                },
                {
                    "section_order": "1000",
                    "section_name": "Profile",
                    "section_id": "profile_data",
                    "has_error": false,
                    "entities": [
                        {
                            "order": "100",
                            "form_entity_type": "BLOCK",
                            "block_id": "first",
                            "block_name": "Personal details",
                            "is_block_multi": true,
                            "block_repeat_max": "",
                            "block_repeat_min": "",
                            "entities": [
                                {
                                    "form_entity_type": "LAYOUT",
                                    "layout_max_constraint": 12,
                                    "layout_id": "newlayoutsd",
                                    "entities": [
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "name",
                                            "label": "Name",
                                            "type": "input",
                                            "placeholder": "Please enter name",
                                            "mandatory": true,
                                            "value": "asdnhiashdaswdkjnasd",
                                            "multi": false,
                                            "editable": true,
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6,
                                            "width_constraint_max_lg": 4,
                                            "width_constraint_max_sm": 12,
                                            "width_constraint_max_md": 6,
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "gender",
                                            "label": "Gender",
                                            "type": "radio",
                                            "options": [
                                                "Male",
                                                "Female",
                                                "Others",
                                                "non-binary"
                                            ],
                                            "mandatory": false,
                                            "multi": true,
                                            "editable": "",
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6,
                                            "width_constraint_max_lg": 12,
                                            "width_constraint_max_sm": 12,
                                            "width_constraint_max_md": 8,
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "fruit",
                                            "label": "Fruits",
                                            "type": "checkbox",
                                            "value": [],
                                            "options": [
                                                "Apple",
                                                "Banana",
                                                "Pineapple"
                                            ],
                                            "mandatory": false,
                                            "multi": false,
                                            "editable": "",
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 12
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "darksouls",
                                            "label": "Darks Souls",
                                            "type": "radio",
                                            "options": [
                                                "Solaire",
                                                "Crestfallen",
                                                "Gwyndolin"
                                            ],
                                            "mandatory": false,
                                            "multi": false,
                                            "editable": "",
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "zeldieee",
                                            "label": "Legend of Zelda",
                                            "type": "radio",
                                            "inputType": "password",
                                            "options": [
                                                "Link",
                                                "Zelda",
                                                "Epona"
                                            ],
                                            "mandatory": false,
                                            "multi": false,
                                            "editable": "",
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6,
                                            "width_constraint_max_sm": 12
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "password",
                                            "placeholder": "Please enter password",
                                            "label": "Password",
                                            "description_text": "Password must be greater than 8 digit and must contain a symbol, a capital letter and a number",
                                            "type": "input",
                                            "inputType": "password",
                                            "mandatory": false,
                                            "multi": false,
                                            "editable": "",
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "password_repeat",
                                            "label": "Password repeat",
                                            "type": "input",
                                            "inputType": "password",
                                            "mandatory": false,
                                            "multi": false,
                                            "editable": "",
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6
                                        },
                                    ]
                                },
                            ]
                        },
                        {
                            "order": "200",
                            "form_entity_type": "BLOCK",
                            "block_id": "newblockanjsdk",
                            "block_name": "Phone Details",
                            "is_block_multi": false,
                            "block_repeat_max": "",
                            "block_repeat_min": "",
                            "entities": [
                                {
                                    "form_entity_type": "LAYOUT",
                                    "layout_max_constraint": 12,
                                    "entities": [
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "date_end",
                                            "label": "End Date",
                                            "type": "date",
                                            "mandatory": "",
                                            "multi": false,
                                            "editable": true,
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 8,
                                            "width_constraint_max_sm": 6,
                                            "width_constraint_max_md": 6,
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "date_st",
                                            "label": "Start Date",
                                            "type": "date",
                                            "mandatory": false,
                                            "multi": false,
                                            "editable": "",
                                            "value": "22-10-2022",
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 4,
                                            "width_constraint_max_sm": 6,
                                            "width_constraint_max_md": 6,
                                        },
                                    ]
                                }
                            ]
                        },
                        {
                            "order": "345345345",
                            "form_entity_type": "FIELD",
                            // "order": "numeric",
                            "field_id": "75654",
                            "label": "Select Country",
                            "description_text": "Please select country you live in, only for Category - Cone",
                            "type": "dropdown",
                            "mandatory": false,
                            "multi": false,
                            "editable": true,
                            "field_repeat_max": "",
                            "field_repeat_min": "",
                            "field_validation_regex": "",
                            "render_contionaly": true,
                            "condition": {
                                "field": "category",
                                "section": "profile_data",
                                "dependence_value": "Cone"
                            }
                        },
                        {
                            "order": "456456456",
                            "form_entity_type": "FIELD",
                            "field_id": "75654",
                            "label": "Main Entity",
                            "description_text": "select to see if this is entity will be a main entity",
                            "type": "toggle",
                            "mandatory": true,
                            "editable": true,
                            "field_repeat_max": "",
                            "field_repeat_min": "",
                            "field_validation_regex": ""
                        },
                        {
                            "order": "567567567567",
                            "form_entity_type": "FIELD",
                            "field_id": "description",
                            "label": "About Me",
                            "type": "textarea",
                            "mandatory": false,
                            "multi": false,
                            "editable": true,
                            "field_repeat_max": "",
                            "field_repeat_min": "",
                            "field_validation_regex": ""
                        }
                    ]
                },
                {
                    "section_order": "200",
                    "section_name": "other",
                    "section_id": "other_data",
                    "section_helper_text": "    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dicta consectetur asperiores ex iste et repellendus ab minima inventore ea tempore cumque, explicabo, accusantium ipsam illum? Tempora molestias laborum magni sint.",
                    "has_error": false,
                    "entities": [
                        {
                            "order": "100",
                            "form_entity_type": "BLOCK",
                            "block_id": "second",
                            "block_name": "More Personal things",
                            "is_block_multi": "true",
                            "block_repeat_max": "",
                            "block_repeat_min": "",
                            "entities": [
                                {
                                    "form_entity_type": "LAYOUT",
                                    "layout_max_constraint": 12,
                                    "layout_id": "newlayoutsd",
                                    "entities": [
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "first_name",
                                            "label": "First Name",
                                            "type": "input",
                                            "placeholder": "Please enter first name",
                                            "mandatory": true,
                                            "multi": false,
                                            "editable": true,
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "last_name",
                                            "label": "Last Name",
                                            "type": "input",
                                            "placeholder": "Please enter first name",
                                            "mandatory": true,
                                            "multi": false,
                                            "editable": true,
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "gender",
                                            "label": "Favorite color",
                                            "type": "radio",
                                            "options": [
                                                "red",
                                                "blue",
                                                "yellow",
                                                "gray"
                                            ],
                                            "mandatory": false,
                                            "multi": false,
                                            "editable": "",
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 5
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "fruit",
                                            "label": "Fruits",
                                            "type": "checkbox",
                                            "options": [
                                                "Apple",
                                                "Banana",
                                                "Pineapple"
                                            ],
                                            "mandatory": false,
                                            "multi": false,
                                            "editable": "",
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "category",
                                            "label": "Category",
                                            "type": "dropdown",
                                            "options": [
                                                "Pines",
                                                "Cone",
                                                "Cherry"
                                            ],
                                            "mandatory": false,
                                            "multi": false,
                                            "editable": "",
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "fruits",
                                            "label": "Darks Souls",
                                            "type": "radio",
                                            "inputType": "password",
                                            "options": [
                                                "Solaire",
                                                "Crestfallen",
                                                "Gwyndolin"
                                            ],
                                            "mandatory": false,
                                            "multi": false,
                                            "editable": "",
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "fruits",
                                            "label": "Lenegnd of Zelda",
                                            "type": "radio",
                                            "inputType": "password",
                                            "options": [
                                                "Link",
                                                "Zelda",
                                                "Epona"
                                            ],
                                            "mandatory": false,
                                            "multi": false,
                                            "editable": "",
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6
                                        }
                                    ]
                                },
                                {
                                    "order": "300",
                                    "form_entity_type": "FIELD",
                                    "field_id": "phone_num",
                                    "label": "Phone Number",
                                    "type": "input",
                                    "inputType": "tel",
                                    "mandatory": true,
                                    "multi": false,
                                    "editable": true,
                                    "field_repeat_max": "",
                                    "field_repeat_min": "",
                                    "field_validation_regex": ""
                                },
                                {
                                    "form_entity_type": "LAYOUT",
                                    "layout_max_constraint": 12,
                                    "entities": [
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "ninja",
                                            "label": "Power Ranger",
                                            "type": "input",
                                            "inputType": "number",
                                            "mandatory": "",
                                            "multi": false,
                                            "editable": true,
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "super",
                                            "label": "Heroes",
                                            "type": "input",
                                            "mandatory": false,
                                            "multi": false,
                                            "editable": "",
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6
                                        },
                                    ]
                                }
                            ]
                        },
                        {
                            "form_entity_type": "FIELD",
                            "field_id": "other_name",
                            "label": "Other Name",
                            "type": "input",
                            "mandatory": "",
                            "multi": false,
                            "editable": true,
                            "field_repeat_max": "",
                            "field_repeat_min": "",
                            "field_validation_regex": ""
                        },
                        {
                            "order": "100",
                            "form_entity_type": "BLOCK",
                            "block_id": "third",
                            "block_name": "More Personal things",
                            "is_block_multi": "true",
                            "block_repeat_max": "",
                            "block_repeat_min": "",
                            "entities": [
                                {
                                    "form_entity_type": "LAYOUT",
                                    "layout_max_constraint": 12,
                                    "entities": [
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "ninja",
                                            "label": "Power Ranger",
                                            "type": "input",
                                            "inputType": "number",
                                            "mandatory": "",
                                            "multi": false,
                                            "editable": true,
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "super",
                                            "label": "Heroes",
                                            "type": "input",
                                            "mandatory": false,
                                            "multi": false,
                                            "editable": "",
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6
                                        },
                                    ]
                                }
                            ]
                        },
                    ]
                },
                {
                    "section_order": "300",
                    "section_name": "Address",
                    "section_id": "address_data",
                    "has_error": false,
                    "entities": [
                        {
                            "form_entity_type": "FIELD",
                            "field_id": "address",
                            "label": "Address",
                            "type": "input",
                            "mandatory": "",
                            "multi": false,
                            "editable": true,
                            "field_repeat_max": "",
                            "field_repeat_min": "",
                            "field_validation_regex": ""
                        },
                        {
                            "order": "123123",
                            "form_entity_type": "FIELD",
                            "field_id": "description",
                            "label": "About Me",
                            "type": "textarea",
                            "mandatory": false,
                            "multi": false,
                            "editable": true,
                            "field_repeat_max": "",
                            "field_repeat_min": "",
                            "field_validation_regex": ""
                        },
                        {
                            "order": "34234542355345345",
                            "form_entity_type": "FIELD",
                            "field_id": "75654",
                            "label": "Add Attachments",
                            "description_text": "less than 5mb",
                            "type": "file",
                            "mandatory": true,
                            "editable": true,
                            "field_repeat_max": "",
                            "field_repeat_min": "",
                            "field_validation_regex": ""
                        },
                    ]
                }
            ],
            sections_data_v2: [
                {
                    "section_order": "1000",
                    "section_name": "Test Section 1",
                    "section_id": "test_1",
                    "has_error": false,
                    "entities": [
                        {
                            "order": "100",
                            "form_entity_type": "BLOCK",
                            "block_id": "first_test",
                            "block_name": "Test block",
                            "is_block_multi": true,
                            "block_repeat_max": "2",
                            "block_repeat_min": "",
                            "entities": [
                                {
                                    "form_entity_type": "LAYOUT",
                                    "layout_max_constraint": 12,
                                    "layout_id": "newlayoutsd",
                                    "entities": [
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "name",
                                            "label": "Name (letters only)",
                                            "type": "input",
                                            "placeholder": "Please enter name",
                                            "mandatory": true,
                                            "value": "TestName",
                                            "multi": false,
                                            "editable": true,
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "^[a-zA-Z]+$",
                                            "width_constraint_max": 6,
                                            "width_constraint_max_lg": 6,
                                            "width_constraint_max_sm": 12,
                                            "width_constraint_max_md": 6,
                                            "error_message": "",
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "email",
                                            "label": "email",
                                            "type": "input",
                                            "placeholder": "Please email",
                                            "mandatory": true,
                                            "value": "",
                                            "multi": true,
                                            "editable": true,
                                            "field_repeat_max": "3",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6,
                                            "width_constraint_max_lg": 6,
                                            "width_constraint_max_sm": 12,
                                            "width_constraint_max_md": 6,
                                            "error_message": "",
                                        },
                                    ]
                                },
                            ]
                        },
                    ]
                },
                {
                    "section_order": "1001",
                    "section_name": "Test Section 2",
                    "section_id": "test_2",
                    "has_error": false,
                    "entities": [
                        {
                            "order": "100",
                            "form_entity_type": "BLOCK",
                            "block_id": "3",
                            "block_name": "Test block",
                            "is_block_multi": true,
                            "block_repeat_max": "2",
                            "block_repeat_min": "",
                            "entities": [
                                {
                                    "form_entity_type": "LAYOUT",
                                    "layout_max_constraint": 12,
                                    "layout_id": "newlayoutsd",
                                    "entities": [
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "3",
                                            "label": "Field 3",
                                            "type": "input",
                                            "placeholder": "Please fill in",
                                            "mandatory": true,
                                            "value": "",
                                            "multi": false,
                                            "editable": true,
                                            "field_repeat_max": "",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6,
                                            "width_constraint_max_lg": 6,
                                            "width_constraint_max_sm": 12,
                                            "width_constraint_max_md": 6,
                                        },
                                        {
                                            "form_entity_type": "FIELD",
                                            "field_id": "4",
                                            "label": "Field 4",
                                            "type": "input",
                                            "placeholder": "Please fill in",
                                            "mandatory": false,
                                            "value": "",
                                            "multi": true,
                                            "editable": true,
                                            "field_repeat_max": "3",
                                            "field_repeat_min": "",
                                            "field_validation_regex": "",
                                            "width_constraint_max": 6,
                                            "width_constraint_max_lg": 6,
                                            "width_constraint_max_sm": 12,
                                            "width_constraint_max_md": 6,
                                            "error_message": "",
                                        },
                                    ]
                                },
                            ]
                        },
                    ]
                },
            ],
            selectedSectionId: null,
        }
    },
    validations() {
        const result = {...this.sections_data.map(el => {
            return this.setValidations(el);
        })};
        return {sections_data: result};
    },
    created() {
        EventBus.$on("changeFieldValue", (data) => {
            console.log('data :>> ', data);
            this.handleChangeFormValue(data);
        });
        // EventBus.$on("addNewBlock", (data) => {
        //     this.addNewBlockEntity(data)
        // });
        // EventBus.$on("removeBlock", (data) => {
        //     this.removeBlock(data)
        // });
        EventBus.$on("copyEntity", (data) => {
            this.copyEntity(data)
        });
        EventBus.$on("removeEntity", (data) => {
            this.removeEntity(data)
        });

        this.sections_data - this.data;

        // select first section by default
        if (this.sections_data.length > 0) {
            this.selectedSectionId = this.sections_data[0].section_id
            // this.sections_data.map(section => {
            //    if (section.entities.length > 0) {
            //        section.entities = section.entities.map(entity =>  ({...entity, _id: this.generateRandomString(true)}) )
            //    }
            //    return section
            // })
        }
    },
    beforeDestroy() {
        EventBus.$off("changeFieldValue");
        // EventBus.$off("addNewBlock");
        // EventBus.$off("removeBlock");
        EventBus.$off("copyEntity");
        EventBus.$off("removeEntity");
    },
    computed: {
        getSections() {
            return this.sections_data.map(sec => ({ id: sec.section_id, name: sec.section_name, order: sec.section_order, has_error: sec.has_error })).sort((a, b) => (b.section_order - a.section_order))
        },
        getSelectedSection() {
            // return this.sections_data[0] NOTE(fix)
            return (this.sections_data.length > 0 && this.selectedSectionId) ? this.sections_data.find(sec => sec.section_id === this.selectedSectionId) : {}
        },
        getSelectedSectionIndex() {
            return (this.sections_data.length > 0 && this.selectedSectionId) ? this.sections_data.findIndex(sec => sec.section_id === this.selectedSectionId) : 0
        },
        errorFromServer() { // TODO
            return !!this.sections_data.find(el => !!el.has_error);
        },
    },
    watch: {
        data(val) {
            this.sections_data = val;
        },
    },
    methods: {
        // setValidations(data, values = {}) {
        //     if (data.entities) {
        //         return {
        //             entities: data.entities.map(el => this.setValidations(el, values)),
        //         }
        //     }
        //     else if (data.form_entity_type === "FIELD") {
        //         const result = {}
        //         // if (data.mandatory) result.required = required;
        //         return { ...values, ...result }
        //     }
        // },
        setValidations(data, values = {}) {
            if (data.entities) {
                return {
                    entities: {...data.entities.map(el => this.setValidations(el, values))},
                }
            }
            else if (data.form_entity_type === "FIELD") {
                const result = {}
                if (data.mandatory) result.required = required;
                if (data.field_validation_regex)
                    result.regex = customValidators.regex(data.field_validation_regex);
                return { 
                    value: {...values, ...result}
                }
            }
        },
        // generateRandomString,
        setCurrentSection(section) {
            if (section && this.selectedSectionId !== section.id) this.selectedSectionId = section.id
        },

        // handleChangeFormValue(data) {
        //     console.log('data :>> ', data);
        //     const findValue = (entity) => {
        //         return entity.form_entity_type && entity.form_entity_type === 'FIELD' && (entity.multi_id ? entity.multi_id === data.multi_id : entity.field_id === data.field_id)
        //     };
        //     const sections_data_copy = cloneDeep(this.sections_data);
        //     const getEntityInEntt = (entity) => {
        //         return entity
        //     }
        //     this.sections_data.forEach((sec, idx_sec) => {
        //         if (findValue(sec)) {
        //             sec.value = data.value
        //             set(sections_data, `[${idx_sec}]`, sec)
        //         }
        //         else if (sec.entities) sec.entities.forEach((block, idx_blk) => {
        //             if (findValue(block)) {
        //                 block.value = data.value;
        //                 set(sections_data_copy, `[${idx_sec}].entities[${idx_blk}]`, block)
        //             }
        //             else if (block.entities) block.entities.forEach((field, idx_fld) => {
        //                 if (findValue(field)) {
        //                     field.value = data.value;

        //                     set(sections_data_copy, `[${idx_sec}].entities[${idx_blk}].entities[${idx_fld}]`, field)
        //                 } else if (field.entities) {
        //                     field.entities.forEach((fld, f_idx) => {
        //                         if (findValue(fld)) {
        //                             fld.value = data.value;
        //                             set(sections_data_copy, `[${idx_sec}].entities[${idx_blk}].entities[${idx_fld}].entities[${f_idx}]`, fld)
        //                         }
        //                     })
        //                 }
        //             })
        //         })
        //     })
        //     this.sections_data = sections_data_copy
        //     this.$forceUpdate();
        // },

        // async addNewBlockEntity({ block, block_id }) {
        //     block = cloneDeep(block)
        //     block.is_last = false
        //     block.previous = null
        //     block.is_block_multi = false
        //     let new_block = cloneDeep(block)
        //     new_block.is_last = true
        //     new_block.is_block_multi = true
        //     new_block.previous =block._id
        //     new_block.next = null
        //     new_block._id = this.generateRandomString(true)
        //     block.next = new_block._id

        //     const generateField = (field) => field.form_entity_type === 'FIELD' ? { ...field, value: null, multi_id: this.generateRandomString(true) } : field

        //     const findBlock = (block_data) => block_data.form_entity_type === 'BLOCK' && (block_data._id === block_id)

        //     if (new_block.entities && new_block.entities.length > 0) {
        //         new_block.entities = new_block.entities.map(blk => blk.form_entity_type === 'LAYOUT' ? { ...blk, entities: blk.entities.map(fld => generateField(fld)) } : generateField(blk))
        //     }


        //     let section_data_copy = cloneDeep(this.sections_data)

        //     section_data_copy.forEach((section, sec_index) => {
        //         // SECTIONS -> BLOCK / LAYOUT / FIELD 
        //         // BLOCK --->  LAYOUT / FIELD 
        //         // LAYOUT --->  FIELD 
        //         if (section.entities && section.entities.length > 0) {
        //             section.entities.every((entity, index) => {
        //                 if (findBlock(entity)) {
        //                     let sec_entities = cloneDeep(section.entities)
        //                     // new_block.block_id = block_id
        //                     sec_entities.splice(index, 1, block)
        //                     sec_entities.splice(Math.abs(index + 1), 0, new_block)
        //                     set(section_data_copy, `[${sec_index}].entities`, sec_entities)
        //                     return false
        //                 }
        //                 else return true
        //             });
        //         }
        //     }),
        //         console.log('block :>> ', block);
        //     console.log('new_block :>> ', new_block);
        //     this.sections_data = []
        //     this.sections_data = section_data_copy
        //     this.$forceUpdate()
        // },

        // async removeBlock({block, _id }) {
        //     console.log('multi_id,block :>> ', _id,block);
        //     let sections_data_copy = cloneDeep(this.sections_data)
        //     let currentSectionEntitiy = sections_data_copy.find((section) => section.entities.some(blk => blk._id === _id))
        //     let next, previous
            
        //     if (block.previous) {
        //         previous = currentSectionEntitiy.entities.find(ent => ent.previous === block.previous)
        //         previous.next = block.next || null
        //     }
        //     if (block.next){
        //        next  = currentSectionEntitiy.entities.find(ent => ent.next === block.next)
        //        next.previous = block.previous || null
        //     }
        //     console.log('currentSectionEntitiy :>> ', currentSectionEntitiy);
        //     currentSectionEntitiy = currentSectionEntitiy.entities.map(ent => {
        //         if (ent._id === next._id) return next
        //         else if (ent._id === previous._id) return previous
        //         else return ent 
        //     })
        //     sections_data_copy = sections_data_copy.map(el => el.section_id === currentSectionEntitiy.section_id)
        //     console.log('previous :>> ', previous);
        //     if (next)  console.log('next :>> ', next);
        //     console.log('this.sections_data :>> ', sections_data_copy);
        //     this.sections_data = sections_data_copy
        //     this.$forceUpdate()
        // }

        getEntityPath(source, path, resultGenerator, i = 0, result = []) {
            const getEntityIndex = (entities, currPath) => {
                return entities.findIndex(el => el[currPath.field] === currPath.value).toString();
            }
            const currIndex = getEntityIndex(source, path[i]);
            let currResult = [...result, currIndex];
            // if it was the last el generate result depending on case
            if (i === path.length - 1) return resultGenerator(currResult);
            else return this.getEntityPath(source[currIndex].entities, path, resultGenerator, i + 1, currResult);
        },
        handleChangeFormValue(data) {
            const resultGenerator = (result) => {
                return result
                    .map((el, index) => index === (data.path.length - 1) ? `[${el}]` : `[${el}].entities`)
                    .join('');
            }
            // func for finding path to the current field for _.set
            const pathToField = this.getEntityPath(this.sections_data, data.path, resultGenerator);
            console.log('path to field: ', pathToField); // TODO remove

            set(this.sections_data, `${pathToField}.value`, data.value);
            set(this.sections_data, `${pathToField}.error_message`, ''); // ?
            // const validator = get(this.$v.sections_data, `${pathToField}.value`);
        },
        resetFields(data, values = {}) {
            if (data.entities) {
                data.entities = data.entities.map(el => this.resetFields(el, values));
                return data;   
            }
            else return { ...data, ...values }
        },
        // main function for entity copy/remove
        changeEntity(path, entityParams, action) {
            const resultGenerator = (result) => {
                return result
                    // generate path to parent list
                    .filter((el, index) => index <= (path.length - 2))
                    .map(el => `[${el}].entities`)
                    .join('');
            }
            // func for finding path to the current field for _.get
            const pathToField = this.getEntityPath(this.sections_data, path, resultGenerator);
            // get array with original element:
            const originalSource = get(this.sections_data, `${pathToField}`);
            // index of original:
            const originalIndex = originalSource.findIndex(el => el[entityParams.field] === entityParams.value);
            // action depending on case:
            action(originalSource, originalIndex);
        },
        copyEntity(path) {
            const entityParams = path.slice(-1)[0];
            const action = (originalSource, originalIndex) => {
                const original = originalSource[originalIndex];
                // note copies count of the current entity
                if (!original.copy_count) original.copy_count = 0;
                original.copy_count++;

                // alternative copy count calculdating:
                // const copyCount = originalSource.filter(el => el.original_entity === entityParams.value).length;

                const copy = cloneDeep(original);
                const resetValues = {value: ''};
                // disable mandatory only if we copy FIELD entity:
                if (original.form_entity_type === 'FIELD') resetValues.mandatory = false;
                // reset all fields' values
                const newEntity = {
                    ...this.resetFields(copy, resetValues),
                    is_copy: true,
                    // set new id depending on the current entity copies count
                    [entityParams.field]: `${entityParams.value}_${original.copy_count}`,
                    original_entity: original.original_entity || entityParams.value,
                }
                // except property copy_count
                delete newEntity.copy_count;
                // add a new entity to the source list
                // ? add just after the original or after the last copy? 
                originalSource.splice(originalIndex + 1, 0, newEntity);
            }
            this.changeEntity(path, entityParams, action);
        },
        removeEntity(path) {
            const entityParams = path.slice(-1)[0];
            const action = (entitySource, entityIndex) => {
                // remove current entity from its source
                entitySource.splice(entityIndex, 1);
            }
            this.changeEntity(path, entityParams, action);
        },
        async saveForm() {
            this.$v.$touch();
            if (this.$v.$invalid) return;
            // TODO
            try {
                const result = await this.sendData();
                console.log('test response', result);
                this.sections_data = result;
                this.$v.$reset();
            } catch (error) {
                console.log(error)
            }
        },
        sendData() { // TODO remove, adeded for example
            this.$emit('submit', this.sections_data);
        },
    }
}


