
export const conf = {
    BLOCK: {
        id: 'block_id',
        multi: 'is_block_multi',
        max: 'block_repeat_max',
    },
    FIELD: {
        id: 'field_id',
        multi: 'multi',
        max: 'field_repeat_max',
    }
}

export const checkIfMulti = (entity, list) => {
    const entityFields = conf[entity.form_entity_type];
    const count = list.filter(el => el.is_copy && (el.original_entity === entity.original_entity || el.original_entity === entity[entityFields.id])).length + 1;
    return entity[entityFields.multi] &&
        (count < entity[entityFields.max] || !entity[entityFields.max]);
}