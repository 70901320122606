import FormBuilder from "@/components/form-builder/form-builder.js";
import axios from "@/axios";
import loader from "@/components/loader"

export default {
    name: 'form-builder',
    components: {
        FormBuilder,
        loader
    },
    data() {
        return {
            data: null,
        }
    },
    computed: {
        getCaseId() {
            return this.$route.query.case_id;
        },
        token() {
            return this.$route.query.access_token;
        },
    },
    async mounted() {
        await this.fetchForm()
    },
    methods: {
        async fetchForm(){
            const url = `/case/form/${this.getCaseId}/client`;
            try {
                const data = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                });
                this.data = data.data;
            } catch (error) {
                console.error("[+] error in fetching form data", error);
            }
        },
        async saveForm(payload) {
            try {
                await axios.post(`/case/form/${this.getCaseId}/client`, payload, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    }
                })
            } catch (error) {
                console.error("[+] error in fetching form data", error);
            }
            
        },
    }
}


