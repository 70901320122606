<template>
    <div class="section w-full overflow-hidden shadow border border-solid border-gray-200 xl:max-w-3xl 2xl:max-w-4xl mx-auto bg-white rounded-lg form">
        <header class="sm:px-4 lg:px-6 md:px-5 mb-2 text-center md:text-left capitalize py-3 border-0 text-lg font-medium text-gray-700 tracking-wider border-b border-solid border-gray-200 bg-gray-50">
            {{ currentSection.section_name }}
        </header>
        <!-- {{currentSection}} -->
       <div class="lg:px-6 lg:py-5 sm:px-2 sm:py-4 md:px-4 md:py-5">
        <span class="text-gray-600 tracking-wide px-2 mb-4 block" v-text="currentSection.section_helper_text" />
        <fieldset
            class="border-0 outline-none"
            v-for="(entity,idx) in getAllBlockEntities"
            :key="idx+(entity[conf[entity.form_entity_type].id] || entity.multi_id || entity._id) + 'block'"
        >
            <template v-if="entity.form_entity_type === 'BLOCK'">
                <Block
                    :blockData="entity"
                    :path="[
                        {field: 'section_id', value: currentSection.section_id},
                        {field: 'block_id', value: entity.block_id}
                    ]"
                    :isMulti="checkIfMulti(entity, getAllBlockEntities)"
                    :validations="currentValidations[idx].entities"
                />
            </template>
            <template v-else-if="entity.form_entity_type === 'LAYOUT'">
                <!-- // layout type entity  -->
                <Layout
                    :layoutData="entity"
                    :path="[
                        {field: 'section_id', value: currentSection.section_id},
                        {field: 'layout_id', value: entity.layout_id}
                    ]"
                    :validations="currentValidations[idx].entities"
                />
            </template>
            <template v-else-if="entity.form_entity_type === 'FIELD'">
                <!-- // Form fields inputs and texts  -->
                <FormField
                    :fieldData="entity" 
                    :path="[
                        {field: 'section_id', value: currentSection.section_id},
                        {field: 'field_id', value: entity.field_id}
                    ]"
                    :isMulti="checkIfMulti(entity, getAllBlockEntities)"
                    :validations="currentValidations[idx]"
                />
            </template>
        </fieldset>
    </div>
    </div>
</template>

<script>
import Layout from "./layoutForm.vue";
import FormField from "./formField.vue";
import Block from "./formBlock.vue";
import { checkIfMulti, conf } from "../utils"

export default {
    props: {
        currentSection: {
            type: Object,
            require: true,
        },
        currentSectionIndex: {
            type: Number,
            require: true,
        },
        validations: {
            type: Object,
            default: () => null,
        },
    },
    components: {
        Layout,
        FormField,
        Block,
    },
    data() {
        return {
            checkIfMulti,
            conf,
        }
    },
    mounted() {},
    computed: {
        getAllBlockEntities() {
            let block_entities = this.currentSection.entities || [] 
            // let obj = {}
            // const makeLastBlock = (block,isLast) => ({...block, isLast})
            // let currentBlockId = ''
            return block_entities
        },
        currentValidations() {
            return this.validations[this.currentSectionIndex].entities;
        },
    },
};
</script>

<style>
</style>